<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Sample Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sampleStatusFilter"
            :options="sampleStatusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:sampleStatusFilter', val)"
          />
        </b-col>
        <b-col
          v-if="!isClient"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Client</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="clientsFilter"
            :options="clientsOptions"
            class="w-100"
            :reduce="val => val.value.toString()"
            @input="(val) => $emit('update:clientsFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    sampleStatusFilter: {
      type: [String, null],
      default: '',
    },
    sampleStatusOptions: {
      type: Array,
      required: true,
    },
    clientsFilter: {
      type: [String, null],
      default: '',
    },
    clientsOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isClient = userRole === 'client-admin' || userRole === 'client'
    return {
      isClient,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
